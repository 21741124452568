.App {
  main {
    margin: 0 auto;
    padding: 24px;
    max-width: 1400px;

    display: flex;
    flex-direction: column;
    row-gap: 16px;

    h1,
    h2,
    h3,
    h4 {
      margin: 0;
    }

    hr {
      margin: 8px 0 0 0;
      border: 0;
      border-top: 1px solid #ccc;
    }
  }
}
